import React from 'react'
import classes from './Gallery.module.scss'
import Cabinet1_Before from './images/gallery/Cabinet1_Before.jpg'
import Cabinet1_After from './images/gallery/Cabinet1_After.jpg'
import Cabinet2_Before from './images/gallery/Cabinet2_Before.jpg'
import Cabinet2_After from './images/gallery/Cabinet2_After.jpg'
import Cabinet3_Before from './images/gallery/Cabinet3_Before.jpg'
import Cabinet3_After from './images/gallery/Cabinet3_After.jpg'
import Chair1_Before from './images/gallery/Chair1_Before.jpg'
import Chair1_After from './images/gallery/Chair1_After.jpg'
import Chair2_Before from './images/gallery/Chair2_Before.jpg'
import Chair2_After from './images/gallery/Chair2_After.jpg'
import Table1_Before from './images/gallery/Table1_Before.jpg'
import Table1_After from './images/gallery/Table1_After.jpg'
import Table2_Before from './images/gallery/Table2_Before.jpg'
import Table2_After from './images/gallery/Table2_After.jpg'
import Scratch_Before from './images/gallery/Scratch_Before.jpg'
import Scratch_After from './images/gallery/Scratch_After.jpg'
import Board_Before from './images/gallery/Board_Before.jpg'
import Board_After from './images/gallery/Board_After.jpg'
import Ornate1_After from './images/gallery/Ornate1_After.jpg'
import Ornate2_After from './images/gallery/Ornate2_After.jpg'
import Ornate3_After from './images/gallery/Ornate3_After.jpg'
import Ornate1_Before from './images/gallery/Ornate1_Before.jpg'
import Ornate2_Before from './images/gallery/Ornate2_Before.jpg'
import Ornate3_Before from './images/gallery/Ornate3_Before.jpg'
import TableCorner_Before from './images/gallery/TableCorner_Before.jpg'
import TableCorner_After from './images/gallery/TableCorner_After.jpg'
import TableCorner2_Before from './images/gallery/TableCorner2_Before.jpg'
import TableCorner2_After from './images/gallery/TableCorner2_After.jpg'
import Table_Before from './images/gallery/Table_Before.jpg'
import Table_After from './images/gallery/Table_After.jpg'
import Cabinet_Before from './images/gallery/Cabinet_Before.jpg'
import Cabinet_After from './images/gallery/Cabinet_After.jpg'
import KitchenCabinet_Before from './images/gallery/KitchenCabinet_Before.jpg'
import KitchenCabinet_After from './images/gallery/KitchenCabinet_After.jpg'
import TableScratch_Before from './images/gallery/TableScratch_Before.jpg'
import TableScratch_After from './images/gallery/TableScratch_After.jpg'
import TableScratch2_Before from './images/gallery/TableScratch2_Before.jpg'
import TableScratch2_After from './images/gallery/TableScratch2_After.jpg'
import TableScratch3_Before from './images/gallery/TableScratch3_Before.jpg'
import TableScratch3_After from './images/gallery/TableScratch3_After.jpg'
import Corner_Before from './images/gallery/Corner_Before.jpg'
import Corner_After from './images/gallery/Corner_After.jpg'
import Chair_Arm_Before from './images/gallery/Chair_Arm_Before.jpg'
import Chair_Arm_After from './images/gallery/Chair_Arm_After.jpg'
import Cabinet_Scratch_Before from './images/gallery/Cabinet_Scratch_Before.jpg'
import Cabinet_Scratch_After from './images/gallery/Cabinet_Scratch_After.jpg'
import Floor_Before from './images/gallery/Floor_Before.jpg'
import Floor_After from './images/gallery/Floor_After.jpg'
import Floor2_Before from './images/gallery/Floor2_Before.jpg'
import Floor2_After from './images/gallery/Floor2_After.jpg'
import Piano_Before from './images/gallery/Piano_Before.jpg'
import Piano_After from './images/gallery/Piano_After.jpg'
import ChairCracked_Before from './images/gallery/ChairCracked_Before.jpg'
import ChairCracked_After from './images/gallery/ChairCracked_After.jpg'
import ChairLeg_Before from './images/gallery/ChairLeg_Before.jpg'
import ChairLeg_After from './images/gallery/ChairLeg_After.jpg'
import CrushedCorner_Before from './images/gallery/CrushedCorner_Before.jpg'
import CrushedCorner_After from './images/gallery/CrushedCorner_After.jpg'
import TableChipped_Before from './images/gallery/TableChipped_Before.jpg'
import TableChipped_After from './images/gallery/TableChipped_After.jpg'
import CornerSplit_Before from './images/gallery/CornerSplit_Before.jpg'
import CornerSplit_After from './images/gallery/CornerSplit_After.jpg'
import FloorDivot_Before from './images/gallery/FloorDivot_Before.jpg'
import FloorDivot_After from './images/gallery/FloorDivot_After.jpg'
import FloorScrape1_Before from './images/gallery/FloorScrape1_Before.jpg'
import FloorScrape1_After from './images/gallery/FloorScrape1_After.jpg'
import FloorScrape2_Before from './images/gallery/FloorScrape2_Before.jpg'
import FloorScrape2_After from './images/gallery/FloorScrape2_After.jpg'
import TableAbrasion_Before from './images/gallery/TableAbrasion_Before.jpg'
import TableAbrasion_After from './images/gallery/TableAbrasion_After.jpg'
import TableSideHole_Before from './images/gallery/TableSideHole_Before.jpg'
import TableSideHole_After from './images/gallery/TableSideHole_After.jpg'
import TableSplit_Before from './images/gallery/TableSplit_Before.jpg'
import TableSplit_After from './images/gallery/TableSplit_After.jpg'
import CabinetChip_Before from './images/gallery/CabinetChip_Before.jpg'
import CabinetChip_After from './images/gallery/CabinetChip_After.jpg'
import CabinetDoor1_Before from './images/gallery/CabinetDoor1_Before.jpg'
import CabinetDoor1_After from './images/gallery/CabinetDoor1_After.jpg'
import CornerCut_Before from './images/gallery/CornerCut_Before.jpg'
import CornerCut_After from './images/gallery/CornerCut_After.jpg'
import Notch_Before from './images/gallery/Notch_Before.jpg'
import Notch_After from './images/gallery/Notch_After.jpg'
import Scuff_Before from './images/gallery/Scuff_Before.jpg'
import Scuff_After from './images/gallery/Scuff_After.jpg'
import WoodSplit_Before from './images/gallery/WoodSplit_Before.jpg'
import WoodSplit_After from './images/gallery/WoodSplit_After.jpg'
import Cabinet4_Before from './images/gallery/Cabinet4_Before.jpg'
import Cabinet4_After from './images/gallery/Cabinet4_After.jpg'
import CabinetInterior_Before from './images/gallery/CabinetInterior_Before.jpg'
import CabinetInterior_After from './images/gallery/CabinetInterior_After.jpg'
import DoorPaint_Before from './images/gallery/DoorPaint_Before.jpg'
import DoorPaint_After from './images/gallery/DoorPaint_After.jpg'
import Door_Before from './images/gallery/Door_Before.jpg'
import Door_After from './images/gallery/Door_After.jpg'
import Post_Before from './images/gallery/Post_Before.jpg'
import Post_After from './images/gallery/Post_After.jpg'
import TallCabinet_Before from './images/gallery/TallCabinet_Before.jpg'
import TallCabinet_After from './images/gallery/TallCabinet_After.jpg'
import NightStand_Before from './images/gallery/NightStand_Before.jpg'
import NightStand_After from './images/gallery/NightStand_After.jpg'
import {faCamera} from '@fortawesome/free-solid-svg-icons/faCamera'
import Carousel from '@brainhubeu/react-carousel'
import DetailBox from './DetailBox'

const IMAGES = [
    Cabinet1_Before,
    Cabinet1_After,
    Cabinet2_Before,
    Cabinet2_After,
    Cabinet3_Before,
    Cabinet3_After,
    Chair1_Before,
    Chair1_After,
    Chair2_Before,
    Chair2_After,
    Table1_Before,
    Table1_After,
    Table2_Before,
    Table2_After,
    Scratch_Before,
    Scratch_After,
    Board_Before,
    Board_After,
    Ornate1_Before,
    Ornate1_After,
    Ornate2_Before,
    Ornate2_After,
    Ornate3_Before,
    Ornate3_After,
    TableCorner_Before,
    TableCorner_After,
    Cabinet_Before,
    Cabinet_After,
    Table_Before,
    Table_After,
    KitchenCabinet_Before,
    KitchenCabinet_After,
    TableScratch_Before,
    TableScratch_After,
    Corner_Before,
    Corner_After,
    Chair_Arm_Before,
    Chair_Arm_After,
    Cabinet_Scratch_Before,
    Cabinet_Scratch_After,
    Floor_Before,
    Floor_After,
    Floor2_Before,
    Floor2_After,
    TableCorner2_Before,
    TableCorner2_After,
    TableScratch2_Before,
    TableScratch2_After,
    TableScratch3_Before,
    TableScratch3_After,
    Piano_Before,
    Piano_After,
    ChairCracked_Before,
    ChairCracked_After,
    ChairLeg_Before,
    ChairLeg_After,
    CrushedCorner_Before,
    CrushedCorner_After,
    TableChipped_Before,
    TableChipped_After,
    CornerSplit_Before,
    CornerSplit_After,
    FloorDivot_Before,
    FloorDivot_After,
    FloorScrape1_Before,
    FloorScrape1_After,
    FloorScrape2_Before,
    FloorScrape2_After,
    TableAbrasion_Before,
    TableAbrasion_After,
    TableSideHole_Before,
    TableSideHole_After,
    TableSplit_Before,
    TableSplit_After,

    CabinetChip_Before,
    CabinetChip_After,
    CabinetDoor1_Before,
    CabinetDoor1_After,
    CornerCut_Before,
    CornerCut_After,
    Notch_Before,
    Notch_After,
    Scuff_Before,
    Scuff_After,
    WoodSplit_Before,
    WoodSplit_After,

    Cabinet4_Before,
    Cabinet4_After,
    CabinetInterior_Before,
    CabinetInterior_After,
    DoorPaint_Before,
    DoorPaint_After,
    Post_Before,
    Post_After,

    TallCabinet_Before,
    TallCabinet_After,
    NightStand_Before,
    NightStand_After,

    Door_Before,
    Door_After,
]

interface GalleryItemProps {
    img: string
    isBefore?: boolean
}

function GalleryItem({img, isBefore = true}: GalleryItemProps) {
    const note = isBefore ? 'Before' : 'After'

    return (
        <span className={classes.galleryItemContainer}>
			<img src={img} alt={note}/>

			<div className={classes.note}>
				{note}
			</div>
		</span>
    )
}

export default function Gallery() {
    return (
        <div className={classes.root}>
            <DetailBox
                title='Gallery'
                icon={faCamera}
            >
                <Carousel
                    slidesPerPage={2}
                    slidesPerScroll={2}
                    arrows
                    infinite
                    autoPlay={5000}
                    keepDirectionWhenDragging
                    breakpoints={{
                        600: {
                            slidesPerPage: 1,
                            slidesPerScroll: 1,
                            arrows: false
                        },
                        900: {
                            slidesPerPage: 1,
                            slidesPerScroll: 1,
                            arrows: true
                        }
                    }}
                >
                    {
                        IMAGES.map((image, index) => (
                            <GalleryItem key={index} img={image} isBefore={index % 2 === 0}/>
                        ))
                    }
                </Carousel>
            </DetailBox>
        </div>
    )
}
