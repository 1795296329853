import React from 'react'
import classes from './Title.module.scss'
// @ts-ignore
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons/faPhoneAlt'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope'
import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons/faAngleDoubleDown'
import { isChrome, isMobile, isMobileSafari } from 'react-device-detect'
import clsx from 'clsx'

export default function Title() {
	const useVhOverride = (isMobile && isChrome) || isMobileSafari

	return (
		<div className={clsx(classes.title, {[classes.vhOverride]: useVhOverride})}>
			<div className={clsx(classes.text, classes.titleItem)}>
				<h1>
					South<br/>
					Jersey<br/>

					<hr/>

					Furniture<br/>
					Repair
				</h1>

				<h2>
					Complete Furniture Repair Services
				</h2>
			</div>


			<div className={clsx(classes.contact, classes.titleItem)}>
				<h4>
					Contact Us

					<hr/>
				</h4>

				<address>
					<a href='mailto:tom@southjerseyfurniturerepair.com?subject=[Furniture Repair] Inquiry&body=Please describe your inquiry below:%0D%0A%0D%0A'>
						<FontAwesomeIcon icon={faEnvelope} className={classes.icon}/>
						tom@southjerseyfurniturerepair.com
					</a>

					<br/>

					<a href='tel:6096808025'>
						<FontAwesomeIcon icon={faPhoneAlt} className={classes.icon}/>
						Tom @ (609) 680-8025
					</a>
				</address>
			</div>

			<FontAwesomeIcon
				icon={faAngleDoubleDown}
				size='2x'
				className={classes.scrollIcon}
			/>
		</div>
	)
}