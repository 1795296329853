import React from 'react'
import classes from './Testimonials.module.scss'
import homeAdvisorLogo from './images/HomeAdvisorLogo.png'
import yelpLogo from './images/Logo_RGB.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar'
import DetailBox from './DetailBox'
import { faComment } from '@fortawesome/free-solid-svg-icons/faComment'

const HomeAdvisorStar = () => (
	<FontAwesomeIcon
		icon={faStar}
		className={classes.star}
		size='2x'
	/>
)

export default function Testimonials() {
	return (
		<div className={classes.root} id='testimonials'>
			<DetailBox
				title='Testimonials'
				icon={faComment}
			>

				<div>
					<img
						src={homeAdvisorLogo}
						alt='HomeAdvisor'
						className={classes.logo}
					/>
				</div>

				<hr/>

				<div
					className={classes.testimonialContainer}
				>

					<div className={classes.ratingContainer}>
						<div className={classes.ratingText}>
							<span className={classes.rating}>4.92</span> | 89 Verified Ratings
						</div>

						<HomeAdvisorStar/>
						<HomeAdvisorStar/>
						<HomeAdvisorStar/>
						<HomeAdvisorStar/>
						<HomeAdvisorStar/>
					</div>

					<div>
						<blockquote>
							Tom was prompt, knowledgeable, and pleasant. He repaired our recliner mechanism efficiently
							for a reasonable price. He was a pleasure to deal with and I would highly recommend him.

							<footer>
								by Suzanne Seavy
							</footer>
						</blockquote>

						<hr/>

						<blockquote>
							Tom returned our initial call within a 1/2 hour, answered our questions and gave an
							estimated
							price. We were able to drop off the piece and were hoping to have it back in a week. Tom
							called
							3 days later to say the repair was completed. The cost was lower than expected and I believe
							the
							chair is stronger than new. Craftsman are a rare find and we would definitely hire him
							again.

							<footer>
								by Betty H. in <cite>Pennsauken, NJ</cite>
							</footer>
						</blockquote>

						<hr/>

						<blockquote>
							Tom was a very reliable business man and did quality work to repair our kitchen table leg.
							We highly recommend him.

							<footer>
								by Caroline & Frank H. in <cite>Runnemede, NJ</cite>
							</footer>
						</blockquote>

						<hr/>

						<blockquote>
							We sent an email for a quote, he called us that night, came the next day. Was very
							professional and polite. Fixed our sofa within an hour by re-attaching the braces to the
							sofa frame. We thought we were going to have to buy a new sofa, but he repaired ours so
							beautifully, it's good as new - strong and sturdy. The price he quoted was fair considering
							we got our sofa back.

							<footer>
								by Janet C. in <cite>Clementon, NJ</cite>
							</footer>
						</blockquote>
					</div>
				</div>

				<hr/>

				<div className={classes.reviewContainer}>
					<h1>Want to support us by writing a review?</h1>

					<div className={classes.reviews}>
						<a href="https://www.yelp.com/writeareview/biz/9UyavP4f7aFGwgDeSe0hBA" rel="noopener noreferrer" target="_blank">
							<img src={yelpLogo} alt='Yelp Logo'/>
						</a>

						<a href="https://g.page/r/CXPHfM-WpAdkEAg/review" rel="noopener noreferrer" target="_blank">
							<div className={classes.googleReview}>
								Review us <br/>
								on {' '}
								<span className={classes.googleText}>
							<span>G</span>
							<span>o</span>
							<span>o</span>
							<span>g</span>
							<span>l</span>
							<span>e</span>
						</span>
							</div>
						</a>

						<a href="https://www.homeadvisor.com/write-a-review/6023260/" rel="noopener noreferrer" target="_blank">
							<div className={classes.homeAdvisorReview}>
								<img src={homeAdvisorLogo} alt='Home advisor logo'/>
							</div>
						</a>
					</div>
				</div>
			</DetailBox>
		</div>
	)
}
