import React from 'react'
import classes from './Footer.module.scss'

export default function Footer() {
	return (
		<footer className={classes.root}>
			Copyright <strong>South Jersey Furniture Repair, LLC</strong> 2023
		</footer>
	)
}
