import React, { useCallback, useState } from 'react'
import classes from './ContactUs.module.scss'
import DetailBox from './DetailBox'
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons/faEnvelopeOpenText'
import clsx from 'clsx'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons/faPaperPlane'
import * as Validation from '../common/validation'
import { validateEmail, validateMessage, validateName, validatePhone, ValidationError } from '../common/validation'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'

enum SubmitState {
	None,
	Submitting,
	Success
}

const stateIcon = {
	[SubmitState.None]: faPaperPlane,
	[SubmitState.Submitting]: faSpinner,
	[SubmitState.Success]: faCheck
}

const SubmitIcon = ({submitState}: { submitState: SubmitState }) => (
	<FontAwesomeIcon
		icon={stateIcon[submitState]}
		pulse={submitState === SubmitState.Submitting}
		className={classes.icon}
	/>
)

export default function ContactUs() {
	const [submitState, setSubmitState] = useState<SubmitState>(SubmitState.None)
	const [submitError, setSubmitError] = useState<string | null>(null)
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [phone, setPhone] = useState('')
	const [message, setMessage] = useState('')

	const onSubmit = useCallback(async (e: React.FormEvent) => {
		e.preventDefault()

		setSubmitState(SubmitState.Submitting)
		setSubmitError(null)

		try {
			validateName(name)
			validateEmail(email)
			validatePhone(phone)
			validateMessage(message)

			const newlineFriendlyMessage = message.replace(/[\r\n]/g, '<br />')

			await axios.post('https://us-central1-sjfurniturerepair.cloudfunctions.net/postForm', {
				name,
				email,
				phone,
				message: newlineFriendlyMessage
			})

			setSubmitState(SubmitState.Success)
			setName('')
			setEmail('')
			setPhone('')
			setMessage('')

			await new Promise(res => setTimeout(res, 2000))

			setSubmitState(SubmitState.None)
		} catch (e) {
			if (e instanceof ValidationError) {
				setSubmitError(e.message)
			} else {
				setSubmitError('Failed to send. Please try again in a few minutes.')
			}
			setSubmitState(SubmitState.None)
		}
	}, [name, email, phone, message])

	const messageLength = message.length
	const isMessageTooLong = messageLength > Validation.MAX_MESSAGE_LENGTH

	const isFormDisabled = submitState !== SubmitState.None

	return (
		<div className={classes.root} id='contact-us'>
			<DetailBox
				title='Contact Us'
				icon={faEnvelopeOpenText}
			>
				<div className={classes.grid}>
					<div className={classes.col}>
						<h2>Call us</h2>
						<a href='tel:6096808025'>
							Tom @ (609) 680-8025
						</a>

						<h2>Email us</h2>
						<a href='mailto:tom@southjerseyfurniturerepair.com?subject=[Furniture Repair] Inquiry&body=Please describe your inquiry below:%0D%0A%0D%0A'>
							tom@southjerseyfurniturerepair.com
						</a>
					</div>

					<div className={classes.col}>
						<h2>Drop off to us</h2>
						<address>
							<strong>South Jersey Furniture Repair, LLC</strong><br/>
							535 Oak Ave<br/>
							Malaga, NJ 08328
						</address>
					</div>

					<iframe
						title='Google Map'
						className={classes.col}
						src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12298.551684904012!2d-75.0684519!3d39.59031!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6407a496cf7cc773!2sSouth+Jersey+Furniture+Repair+LLC!5e0!3m2!1sen!2sus!4v1565613286405!5m2!1sen!2sus"
						allowFullScreen/>
				</div>

				<hr/>

				<p>
					Please fill out all fields before submitting.
				</p>

				<form onSubmit={onSubmit} noValidate={true}>
					<div className={clsx(classes.field, classes.col)}>
						<label>
							Name
						</label>
						<input
							placeholder='Your name'
							type='text'
							name='name'
							disabled={isFormDisabled}
							value={name}
							onChange={e => setName(e.target.value)}
						/>
					</div>

					<div className={clsx(classes.field, classes.col)}>
						<label>
							Email
						</label>
						<input
							placeholder='you@example.com'
							type='email'
							name='email'
							disabled={isFormDisabled}
							value={email}
							onChange={e => setEmail(e.target.value)}
						/>
					</div>

					<div className={clsx(classes.field, classes.col)}>
						<label>
							Phone
						</label>
						<input
							placeholder='(555)-123-4567'
							type='tel'
							name='phone'
							disabled={isFormDisabled}
							value={phone}
							onChange={e => setPhone(e.target.value)}
						/>
					</div>

					<div className={classes.field}>
						<label>
							Message
						</label>
						<textarea
							placeholder='Put your message to us here!'
							name='message'
							value={message}
							rows={4}
							disabled={isFormDisabled}
							onChange={e => setMessage(e.target.value)}
						/>
						<div className={classes.hint}>
							Message length
							{' '}
							<span className={clsx({[classes.error]: isMessageTooLong})}>
								{messageLength}
							</span> / {Validation.MAX_MESSAGE_LENGTH}
							{isMessageTooLong && <span className={classes.error}> [Too long!]</span>}
						</div>
					</div>

					<div className={classes.field}>
						<button
							type='submit'
							className={clsx({[classes.successBackground]: submitState === SubmitState.Success})}
							disabled={isFormDisabled}
						>
							<SubmitIcon
								submitState={submitState}
							/>
							{submitState === SubmitState.Success ? 'Success!' : 'Send'}
						</button>
					</div>

					{submitError && (
						<div className={clsx(classes.field, classes.error)}>
							{submitError}
						</div>
					)}
				</form>
			</DetailBox>
		</div>
	)
}