const phoneRegex = /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/

const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i

export const MIN_STRING_LENGTH = 2
export const MAX_MESSAGE_LENGTH = 1024

export class ValidationError extends Error {}

export function validatePhone(phone: string) {
	if (!phoneRegex.test(phone))
		throw new ValidationError('Phone number is invalid!')
}

export function validateEmail(email: string) {
	if (!emailRegex.test(email))
		throw new ValidationError('Email is invalid!');
}


/**
 * Validates the contact's name
 * @param name Personal name - Length: [{MIN_STRING_LENGTH}, 256]
 */
export function validateName(name: string) {
	if (name.length < MIN_STRING_LENGTH)
		throw new ValidationError('Name is too short!')

	if (name.length > 256)
		throw new ValidationError('Name is too long!')
}


/**
 * Validates the contact's message
 * @param message Message to include with the email - Length: [{MIN_STRING_LENGTH}, {MAX_MESSAGE_LENGTH}]
 */
export function validateMessage(message: string) {
	if (message.length < MIN_STRING_LENGTH)
		throw new ValidationError('Message too short!')

	if (message.length > MAX_MESSAGE_LENGTH)
		throw new ValidationError('Message too long!')
}