import React from 'react'
import classes from './Main.module.scss'
import DetailBox from './DetailBox'
import { faCouch } from '@fortawesome/free-solid-svg-icons/faCouch'
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome'
import { faClipboardList } from '@fortawesome/free-solid-svg-icons/faClipboardList'

const InHomeRepairs = () => (
	<DetailBox
		icon={faHome}
		title={<span>In-Home <br/>Repairs</span>}
	>
		<div className={classes.content}>
			<p>
				We can come to you! <a href='#contact-us'>Contact us</a> to arrange an in-house repair of your furniture.
			</p>

			<p>
				Does your business need repairs? No problem! We service businesses, too.
			</p>

			<p>
				We service the following counties: <span className={classes.counties}>Camden, Gloucester, Cumberland, Salem, Atlantic, and Cape May</span>
			</p>
		</div>

		<blockquote className={classes.quote}>
			"Restored a 1930s English Art Deco china cabinet that had fallen apart over the years and during a recent
			move. Did the work in my home. He was very polite, professional and a true craftsman. He took extra care
			with each piece and with the rooms where he worked. I was amazed at the results. I never thought it would
			look so good when he was done. He protected my floors and other furniture while he worked. I was very
			pleased with the results, the price and the superior level of professionalism and customer service so rare
			these days."
			<footer>
				Vincent V. in <cite>Haddonfield, NJ</cite>
			</footer>
		</blockquote>
	</DetailBox>
)

const ClaimSettlement = () => (
	<DetailBox
		icon={faClipboardList}
		title={<span>Claim <br/>Settlement</span>}
	>
		<div className={classes.content}>
			<p>
				Have you suffered damage to your furniture due to weather or mishandling during a move?
				We can work with your insurance provider or moving company to create an estimate for damages.
			</p>
		</div>

		<blockquote className={classes.quote}>
			"I had a bad experience with ordering furniture on line the company shipped it freight rather than set up.
			Tom was able to come out put everything together and there were a few damaged pieces - he spoked to the
			company for me and wrote them a detailed description of the damage. He was very professional and courteous -
			I would definitely recommend his services to friends and family."
			<footer>
				Cathy K. in <cite>Swedesboro, NJ</cite>
			</footer>
		</blockquote>
	</DetailBox>
)

const RepairAndReplacements = () => (
	<DetailBox
		icon={faCouch}
		title={<span>Repair &<br/> Replacements</span>}
	>
		<div className={classes.content}>
			<p>
				We can repair your old or damaged furniture, and we can replace missing or broken parts. Here's a short
				list
				of what we can do for you:
			</p>

			<div>
				<ul className={classes.splitList}>
					<li>Touch Up & Repair Kitchen Cabinets</li>
					<li>Repair Nicks & Scratches</li>
					<li>Repair Worn Surfaces</li>
					<li>Replace Components</li>
					<li>Repair Sofa Frames & Springs</li>
					<li>Repair Recliners</li>
					<li>Tighten Loose Furniture</li>
					<li>Repair Pet Damage</li>
					<li>Repair Hardwood Floors</li>
					<li>Touch Up RV Interiors, Motor-home Panels, Steering Wheels, and Furniture</li>
					<li>Front Door Repairs</li>
				</ul>

				<p className={classes.note}>
					Please note: we do <strong>not</strong> refinish. Sorry!
				</p>
			</div>
		</div>

		<blockquote className={classes.quote}>
			"Received a phone call, very mannerly, picked up the three chairs that needed to be repaired. One needed to
			have spindles filled in because dog chewed them and you would never know. Fabulous job and reasonable. Am
			very happy and would highly recommend."
			<footer>
				Barbara T. in <cite>Haddonfield, NJ</cite>
			</footer>
		</blockquote>
	</DetailBox>
)

export default function Main() {
	return (
		<main className={classes.serviceItemContainer} id='main'>
			<InHomeRepairs/>

			<ClaimSettlement/>

			<RepairAndReplacements/>
		</main>
	)
}
