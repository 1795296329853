import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import classes from './DetailBox.module.scss'
import clsx from 'clsx'

interface Props {
	title: React.ReactNode
	children: React.ReactNode
	icon: IconProp
	className?: string
}

export default function DetailBox({title, children, icon, className}: Props) {
	return (
		<article className={clsx(classes.root, className)}>
			<h3>
				<FontAwesomeIcon icon={icon} className={classes.icon}/>

				{title}
			</h3>
			<div className={classes.body}>
				{children}
			</div>
		</article>
	)
}