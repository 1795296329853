import React from 'react'
import '@brainhubeu/react-carousel/lib/style.css'
import Title from './Title'
import Main from './Main'
import Testimonials from './Testimonials'
import ContactUs from './ContactUs'
import Footer from './Footer'
import Gallery from './Gallery'

export default function App() {
	return (
		<React.Fragment>
			<Title/>

			<Main/>

			<Testimonials/>

			<Gallery/>

			<ContactUs/>

			<Footer/>
		</React.Fragment>
	)
}
